import React from 'react';
import Img from "gatsby-image/withIEPolyfill";

const Hero = (props) => {
  return (
    <div style={{position: 'relative', width: '100%'}}>
      <Img
      objectPosition={props.posY+props.posX}
      objectFit='cover'
      fluid={props.fluid} style={{
       position: 'relative',
       left: 0,
       top: 0,
       width: `${props.width}`,
       height: `${props.height}`,
       zIndex: "1"
     }}
     imgStyle={{
       objectPosition: `${props.posY} ${props.posX}`,
       objectFit: 'cover',
       opacity: '1'
     }} />
     </div>
  )
}
export default Hero;
